<template>
<div>
    <!-- <v-row>
        <v-col cols="12" md="3">
            <v-alert text class="text-center" border="bottom">
                <h3>{{ $service.formatCurrency(totals.totalUnpaidMerchantAmount) }} د.ع</h3>
                <h4 class="mt-2">مجموع مستحقات التجار الغير مدفوعة</h4>
            </v-alert>
        </v-col>
        <v-col cols="12" md="3">
            <v-alert text class="text-center" border="bottom">
                <h3>{{ $service.formatCurrency(totals.totalPaidMerchantAmount) }} د.ع</h3>
                <h4 class="mt-2">مجموع مستحقات التجار المدفوعة</h4>
            </v-alert>
        </v-col>
        <v-col cols="12" md="3">
            <v-alert text class="text-center" border="bottom">
                <h3>{{ $service.formatCurrency(totals.totalUnpaidDelegaterAmount) }} د.ع</h3>
                <h4 class="mt-2">مجموع مستحقات المندوبين الغير مدفوعة</h4>
            </v-alert>
        </v-col>
        <v-col cols="12" md="3">
            <v-alert text class="text-center" border="bottom">
                <h3>{{ $service.formatCurrency(totals.totalPaidDelegaterAmount) }} د.ع</h3>
                <h4 class="mt-2">مجموع مستحقات المندوبين المدفوعة</h4>
            </v-alert>
        </v-col>
        <v-col cols="12" md="3">
            <v-alert text class="text-center" border="bottom">
                <h3>{{ $service.formatCurrency(totals.totalUnpaidDelegaterAmountDebits) }} د.ع</h3>
                <h4 class="mt-2">مجموع ذمم المندوبين الغير مستلمة</h4>
            </v-alert>
        </v-col>
        <v-col cols="12" md="3">
            <v-alert text class="text-center" border="bottom">
                <h3>{{ $service.formatCurrency(totals.totalPaidDelegaterAmountDebits) }} د.ع</h3>
                <h4 class="mt-2">مجموع ذمم المندوبين المستلمة</h4>
            </v-alert>
        </v-col>
        <v-col cols="12" md="3">
            <v-alert text class="text-center" border="bottom" color="teal">
                <h3>{{ $service.formatCurrency(totals.totalPaidAmount) }} د.ع</h3>
                <h4 class="mt-2">اجمالي المستحقات المدفوعة</h4>
            </v-alert>
        </v-col>
        <v-col cols="12" md="3">
            <v-alert text class="text-center" border="bottom" color="error">
                <h3>{{ $service.formatCurrency(totals.totalUnPaidAmount) }} د.ع</h3>
                <h4 class="mt-2">اجمالي المستحقات الغير مدفوعة</h4>
            </v-alert>
        </v-col>

    </v-row> -->

    <v-row align="center" class=" mb-3">
        <!-- <v-col cols="12" md="2" >
            <v-btn @click="openAddDialog()" color="primary" large class="btn">
                <v-icon class="ml-2">add_circle_outline</v-icon>
                <h3>إضافة {{this.$route.meta.single}}</h3>
            </v-btn>
        </v-col> -->
        <v-spacer />
        <v-col cols="12" md="3">
            <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="searchName" label="بحث حسب الاسم" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
        </v-col>
        <v-col cols="12" md="3">
            <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="filter.orderNo" label="بحث حسب رقم الشحنة" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
        </v-col>
        <aAutoComplete v-model="filter.transactionType" :items="$global.state.transactionTypes" item-text="name" item-value="id" title="النوع" @input="getItems()" :cols="12" :md="2" :dense="true" :required="false" :fieldTitle="false" />
        <aAutoComplete v-model="filter.isPaid" :items="$global.state.paymentTypes" item-text="name" item-value="id" title="الحالة" @input="getItems()" :cols="12" :md="2" :dense="true" :required="false" :fieldTitle="false" />
        <aAutoComplete v-model="filter.userType" :items="$global.state.userTypes" item-text="name" item-value="id" title="نوع المستخدم" @input="getItems()" :cols="12" :md="2" :dense="true" :required="false" :fieldTitle="false" />
        <aDatePicker @input="getItems()" v-model="filter.fromDate" title="بحث من تاريخ" :cols="12" :md="2" :dense="true" :required="false" :fieldTitle="false" />
        <aDatePicker @input="getItems()" v-model="filter.toDate" title="بحث الى تاريخ" :cols="12" :md="2" :dense="true" :required="false" :fieldTitle="false" />
        <!-- <v-col cols="12" md="1" class="ml-2"> -->
        <v-btn @click="exportToExcel()" dark color="teal" large class="btn mr-4" :loading="loading">
            <v-icon class="ml-4">fi fi-rr-file-excel</v-icon>
            <h3>تصدير Excel {{this.$route.meta.single}}</h3>
        </v-btn>
        <!-- </v-col> -->

        <!-- <v-col cols="12" md="1" class="mr-12"> -->
        <v-btn @click="print()" dark color="error" large class="btn mr-2" :loading="loading">
            <v-icon class="ml-3">fi fi-rr-file-pdf</v-icon>
            <h3>تصدير pdf {{this.$route.meta.single}}</h3>
        </v-btn>

        <v-btn @click="selectAll()" outlined color="indigo" large class="btn mr-2" :loading="loading">
            <v-icon class="ml-3">fi fi-rr-magic-wand</v-icon>
            <h3>تحديد جميع العناصر</h3>
        </v-btn>

        <v-spacer />

        <v-btn @click="payAll()" :disabled="orderIds.length == 0" dark color="primary" large class="btn ml-4" :loading="loading">
            <v-icon class="ml-4">fi fi-rr-money-bill-wave</v-icon>
            <h3 v-if="orderIds.length == 0">يجب تحديد عناصر للدفع والاستلام من كل العناصر المحددة </h3>
            <h3 v-else>الدفع والاستلام من كل العناصر المحددة</h3>
        </v-btn>
        <!-- </v-col> -->
    </v-row>

    <v-card outlined :key="orderIds.length">
        <v-data-table  v-model="orderIds" :items-per-page="items.length" :items="items" :headers="headers" :loading="loading" hide-default-footer no-data-text="لا توجد بيانات" loading-text="جاري تحميل البيانات" :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'">
            <template v-slot:item.select="{ item }">
                <v-simple-checkbox :value="orderIds.includes(item.id)" :disabled="item.isPaid != 0" @input="onCheckboxInput(item, $event)"></v-simple-checkbox>
            </template>

            <template v-slot:item.note="{item}">
                <div>{{ item.note == '' ? 'لا يوجد' : item.note }}</div>
            </template>
            <template v-slot:item.user.fullName="{item}">
                <div>{{ item.user.fullName }} ( {{ item.user.userType.name }} )</div>
            </template>
            <template v-slot:item.amount="{item}">
                <div>{{ $service.formatCurrency(item.amount) }}</div>
            </template>
            <template v-slot:item.paidAmount="{item}">
                <div>{{ $service.formatCurrency(item.paidAmount) }}</div>
            </template>
            <template v-slot:item.toPay="{item}">
                <div>{{ $service.formatCurrency(item.amount - item.paidAmount) }}</div>
            </template>
            <template v-slot:item.description="{item}">
                <div>مبلغ الشحنة رقم: {{ item.description }} ( <a href="">{{ item.order.no }}#</a> )</div>
            </template>
            <template v-slot:item.payDate="{item}">
                <div v-if="item.payDate != '0001-01-01T00:00:00'">
                    {{item.payDate.substr(0,10)}}  
                    <br/>
                    <span class="grey--text" dir="ltr">{{ formatDate(item.payDate) }}</span>

                </div>
                <div v-if="item.payDate == '0001-01-01T00:00:00'">
                    --:---:---
                </div>
            </template>
            <template v-slot:item.transactionType="{item}">
                <v-chip v-if="item.transactionType == 1" color="error" dark> ذمة </v-chip>
                <v-chip v-if="item.transactionType == 0" color="indigo" dark> استحقاق </v-chip>
            </template>
            <template v-slot:item.isPaid="{item}">
                <v-chip v-if="item.isPaid == 0" color="orange" dark> معلق </v-chip>
                <v-chip v-if="item.isPaid == 1" color="indigo" dark> {{ item.transactionType == 0 ? 'مسدد' : 'مستلم' }} جزئي </v-chip>
                <v-chip v-if="item.isPaid == 2" color="teal" dark> {{ item.transactionType == 0 ? 'مسدد' : 'مستلم' }} كامل </v-chip>
            </template>
            <template v-slot:item.actions="{item}">

                <!-- <v-tooltip bottom="" v-if="$store.state.user.roles.find(x => x == `${$route.path.slice(1)}-delete`) || $store.state.user.userType == $global.state.roleType.admin">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="deleteItem(item.id)" v-on="on" icon>
                            <v-icon color="error">
                                fi fi-rr-trash
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>حذف</span>
                </v-tooltip>

                <v-tooltip bottom="">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="openEditDialog(item)" v-on="on" icon>
                            <v-icon color="info">
                                fi fi-rr-edit
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>تعديل</span>
                </v-tooltip> -->

                <v-tooltip bottom="" v-if="$store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-pay`) || $store.state.user.userType.id == $global.state.roleType.admin">
                    <template v-slot:activator="{ on }">
                        <v-btn :disabled="item.isPaid == 2" @click="openPaymentDialog(item)" v-on="on" :color="item.isPaid != 2 ? (item.transactionType == 0 ? 'indigo' : 'teal'): ''" block dark class="btn">
                            <v-icon class="ml-2">
                                {{ item.transactionType == 0 ? 'fi fi-rr-wallet' : 'fi fi-rr-donate' }}
                            </v-icon>
                            <h4>{{ item.transactionType == 0 ? 'تسديد' : 'استلام' }}</h4>
                        </v-btn>
                    </template>
                    <span>
                        {{ item.transactionType == 0 ? 'تسديد' : 'استلام' }}
                    </span>
                </v-tooltip>

            </template>
        </v-data-table>
    </v-card>
    <Pagination />
    <Dialog />
    <PaymentDialog />
    <vue-easy-print class="print-only" ref="easyPrint" >
        <v-app>
            <Print :currentItem="selectedItem"/>
        </v-app> 
    </vue-easy-print>
</div>
</template>
<style scoped>
@media print {
    @page {
        size: A4 landscape;
    }

    body {
        width: 297mm; /* A4 width */
        height: 210mm; /* A4 height */
        margin: 0;
    }

    .print-only {
        display: block;
    }

    

    /* Additional print styles if needed */
}
</style>
<script>
import moment from 'moment';
import vueEasyPrint from "vue-easy-print"; 

export default {

    components: {
        Dialog: () => import("./dialog"),
        PaymentDialog: () => import("./paymentDialog"),
        Print: () => import("./print.vue"),
        vueEasyPrint
    },

    data() {
        return {
            loading: false,
            searchName: "",
            selectedItem: "",
            governorateId: "",
            orderIds: [],
            selected: [],
            filter: {
                transactionType: '',
                isPaid: '',
                orderNo: '',
                userType: '',
                fromDate: '',
                toDate: ''
            },
            totals: {
                "totalUnpaidMerchantAmount": 0,
                "totalPaidMerchantAmount": 0,
                "totalUnpaidDelegaterAmount": 0,
                "totalPaidDelegaterAmount": 0,
                "totalUnpaidDelegaterAmountDebits": 0,
                "totalPaidDelegaterAmountDebits": 0,
                "totalPaidAmount": 0,
                "totalUnPaidAmount": 0
            },
            headers: [{
                    text: "",
                    value: "select"
                },
                {
                    text: "الاسم",
                    value: "user.fullName"
                },
                {
                    text: "الوصف",
                    value: "description"
                },
                {
                    text: "المبلغ",
                    value: "amount"
                },
                {
                    text: "النوع",
                    value: "transactionType"
                },
                {
                    text: "المبلغ (المستلم / المسدد)",
                    value: "paidAmount"
                },
                {
                    text: "المبلغ المتبقي",
                    value: "toPay"
                },
                {
                    text: "ملاحظة",
                    value: "note"
                },
                {
                    text: "تاريخ (الاستلام / الدفع)",
                    value: "payDate"
                },
                {
                    text: "الحالة",
                    value: "isPaid"
                },
                {
                    text: "الاجراءات",
                    value: "actions"
                }
            ],

            items: []
        }
    },

    created() {
        this.getItems()
        setTimeout(() => {
            this.$eventBus.$on(`${this.$route.path}`, () => {
                this.getItems()
            })
        }, 100);
    },

    beforeDestroy() {
        this.$eventBus.$off(`${this.$route.path}`)
    },

    // watch: {
    //     items: {
    //         immediate: true,
    //         handler(newItems) {
    //             this.orderIds = newItems.filter(item => item.isPaid === 0).map(item => item.id);
    //         }
    //     }
    // },

    methods: {
        formatDate(date) {
        return moment(date).format('h:mm:ss a'); // 'LL' is a format string; you can customize it
      },  

      async print(){
            this.loading = true; 
            try {
                let res = await this.$http.get(`paymentTransaction?PageIndex=${this.$global.state.filter.pageIndex}&PageSize=${this.$global.state.count}&Name=${this.searchName}&OrderNo=${this.filter.orderNo}&PaymentType=${this.filter.isPaid}&UserType=${this.filter.userType}&TransactionType=${this.filter.transactionType}&FromDate=${this.filter.fromDate}&ToDate=${this.filter.toDate}`);

                this.selectedItem = res.data.result
                setTimeout(() => {
                    this.$refs.easyPrint.print()
                }, 1500);
            } catch (err) {
                console.log(err)
            } finally{
                this.loading = false
            }
        },
        
        selectAll() {
            let unPaidItems = this.items.filter(item => item.isPaid === 0);

            if (this.orderIds.length !== unPaidItems.length) {
                // Select all unpaid items
                this.orderIds = unPaidItems.map(item => item.id);
            } else {
                // Deselect all
                this.orderIds = [];
            }
        },

        onCheckboxInput(item, newValue) {
            if (newValue) {
                if (!this.orderIds.includes(item.id)) {
                    this.orderIds.push(item.id);
                }
            } else {
                this.orderIds = this.orderIds.filter(id => id !== item.id);
            }
        },

        async getItems() {
            this.loading = true
            try {
                let res = await this.$http.get(`paymentTransaction?PageIndex=${this.$global.state.filter.pageIndex}&PageSize=${this.$global.state.filter.pageSize}&Name=${this.searchName}&OrderNo=${this.filter.orderNo}&PaymentType=${this.filter.isPaid}&UserType=${this.filter.userType}&TransactionType=${this.filter.transactionType}&FromDate=${this.filter.fromDate}&ToDate=${this.filter.toDate}`)
                this.items = res.data.result
                this.$global.state.count = res.data.count
                this.$global.state.length = res.data.count == undefined ? 0 : Math.ceil(res.data.count / this.$global.state.filter.pageSize);
                this.totals = res.data.totalCounts
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async payAll() {
            this.loading = true
            let data = {
                ordersIds: this.orderIds
            }
            // for (let i = 0; i < this.orderIds.length; i++) {
            //     data.ordersIds.push(this.orderIds[i].id)
            // }
            try {
                await this.$http.put(`PaymentTransaction/payListOfOrders`, data)
                this.$service.success('تم الإضافة بنجاح')
                this.getItems()
                this.orderIds = []
            } catch (err) {
                this.$service.faild('حدث خطأ')
                this.$service.faild(err.data.message)
                this.$service.faild(JSON.stringify(err.data.result.message))
            } finally {
                this.loading = false
            }
        },

        async deleteItem(id) {
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`${this.$route.meta.endPoint}`, id)
                        .then((res) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `تم الحذف بنجاح`,
                                type: "success",
                            });
                            this.getItems();
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `حصل خطا اثناء الحذف`,
                                type: "error",
                            });
                        });
                }
            });
        },

        openAddDialog() {
            var item = {
                dialogType: 'add'
            }
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog')
        },

        openEditDialog(item) {
            item.dialogType = 'edit'
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog')
            this.$eventBus.$emit('fill-fields')
        },

        openPaymentDialog(item) {
            item.dialogType = 'edit'
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog2')
            this.$eventBus.$emit('fill-fields')
        },

        async exportToExcel() {
            this.loading = true
            try {
                const res = await this.$http.get('paymentTransaction/export/excel', {
                    params: {
                        Name: this.searchName,
                        OrderNo: this.filter.orderNo,
                        IsPaid: this.filter.isPaid,
                        UserType: this.filter.userType,
                        TransactionType: this.filter.transactionType,
                        FromDate: this.filter.fromDate,
                        ToDate: this.filter.toDate
                    },
                    responseType: 'blob' // to handle the binary data
                });

                // Create a Blob object from the binary data
                const blob = new Blob([res.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                // Create a URL for the Blob object and start download
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Voters.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            } catch (err) {
                console.log(err);
            } finally {
                this.loading = false
            }
        },

        async exportToPdf() {
            this.loading = true
            try {
                const res = await this.$http.get('paymentTransaction/export/pdf', {
                    params: {
                        Name: this.searchName,
                        OrderNo: this.filter.orderNo,
                        IsPaid: this.filter.isPaid,
                        UserType: this.filter.userType,
                        TransactionType: this.filter.transactionType,
                    },
                    responseType: 'blob' // to handle the binary data
                });

                // Create a Blob object from the binary data
                const blob = new Blob([res.data], {
                    type: 'application/pdf'
                });

                // Create a URL for the Blob object and start download
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Voters.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (err) {
                console.log(err);
            } finally {
                this.loading = false
            }
        },
    },

}
</script>
